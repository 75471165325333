<template>
  <div style="padding:16px" v-loading.fullscreen.lock="fullscreenLoading">
    <h3 style="margin-bottom:50px">{{ detail.title }}</h3>
    <h4>工单信息</h4>
    <div class="fielList">
      <p><span>工单号：</span>{{ detail.ticketId || '--' }}</p>
      <p><span>园区：</span>{{ detail.campusName || '--' }}</p>
      <p><span>机房：</span>{{ detail.bizAreaName || '--' }}</p>
      <p><span>机房单元：</span>{{ detail.idcUnitName || '--' }}</p>
      <p><span>工单类型：</span>{{ detail.ticketType || '--' }}</p>
      <p><span>维修类型：</span>{{ detail.repairType || '--' }}</p>
      <p><span>替换类型：</span>{{ detail.replaceType || '--' }}</p>
      <p><span>维修数量：</span>{{ detail.counts || '--' }}</p>
      <p><span>是否高危操作：</span>{{ detail.isDangerOps || '--' }}</p>
      <p><span>上游派单人：</span>{{ detail.assigner || '--' }}</p>
      <p><span>派单时联系人：</span>{{ detail.handler || '--' }}</p>
      <p><span>结单人：</span>{{ detail.completer || '--' }}</p>
      <p><span>期望驻场操作完成时间：</span>{{ detail.expectOperateTime || '--' }}</p>
      <p><span>是否超时：</span>{{ detail.isTimeout || '--' }}</p>
    </div>
    <h4>流程信息</h4>
    <div class="fielList1">
      <p><span>创建时间：</span>{{ detail.createTime || '--' }}</p>
      <p><span>上游派单时间：</span>{{ detail.assignTime || '--' }}</p>
      <p><span>驻场操作完成时间：</span>{{ detail.finishTicketTime || '--' }}</p>
      <p><span>结单时间：</span>{{ detail.completeTime || '--' }}</p>
      <p><span>截止时间：</span>{{ detail.deadline || '--' }}</p>
    </div>
  </div>
</template>

<script>
import { networkDetail } from '@/api/monitor';
export default {
  name: 'networkDetail',
  data() {
    return {
      fullscreenLoading: false,
      detail: {},
    };
  },
  created() {
    this.networkDetail();
  },
  methods: {
    //详情接口
    networkDetail() {
      this.fullscreenLoading = true;
      networkDetail({ticketId:this.$route.query.ticketId})
        .then((res) => {
          this.detail = res.data.data;
          this.fullscreenLoading = false;
        })
        .catch(() => {
          this.fullscreenLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.fielList1{
   p {
    font-size: 14px;
    line-height: 18px;
    margin: 0 80px 16px 0;
    padding: 0 !important;
    color: #333;
    span {
      color: #666;
    }
  }
}
.fielList {
  display: flex;
  flex-wrap: wrap;
  p {
    font-size: 14px;
    line-height: 18px;
    margin: 0 80px 10px 0;
    padding: 0 !important;
    color: #333;
    span {
      color: #666;
    }
  }
}
</style>
