var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      staticStyle: { padding: "16px" }
    },
    [
      _c("h3", { staticStyle: { "margin-bottom": "50px" } }, [
        _vm._v(_vm._s(_vm.detail.title))
      ]),
      _c("h4", [_vm._v("工单信息")]),
      _c("div", { staticClass: "fielList" }, [
        _c("p", [
          _c("span", [_vm._v("工单号：")]),
          _vm._v(_vm._s(_vm.detail.ticketId || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("园区：")]),
          _vm._v(_vm._s(_vm.detail.campusName || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("机房：")]),
          _vm._v(_vm._s(_vm.detail.bizAreaName || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("机房单元：")]),
          _vm._v(_vm._s(_vm.detail.idcUnitName || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("工单类型：")]),
          _vm._v(_vm._s(_vm.detail.ticketType || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("维修类型：")]),
          _vm._v(_vm._s(_vm.detail.repairType || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("替换类型：")]),
          _vm._v(_vm._s(_vm.detail.replaceType || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("维修数量：")]),
          _vm._v(_vm._s(_vm.detail.counts || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("是否高危操作：")]),
          _vm._v(_vm._s(_vm.detail.isDangerOps || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("上游派单人：")]),
          _vm._v(_vm._s(_vm.detail.assigner || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("派单时联系人：")]),
          _vm._v(_vm._s(_vm.detail.handler || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("结单人：")]),
          _vm._v(_vm._s(_vm.detail.completer || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("期望驻场操作完成时间：")]),
          _vm._v(_vm._s(_vm.detail.expectOperateTime || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("是否超时：")]),
          _vm._v(_vm._s(_vm.detail.isTimeout || "--"))
        ])
      ]),
      _c("h4", [_vm._v("流程信息")]),
      _c("div", { staticClass: "fielList1" }, [
        _c("p", [
          _c("span", [_vm._v("创建时间：")]),
          _vm._v(_vm._s(_vm.detail.createTime || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("上游派单时间：")]),
          _vm._v(_vm._s(_vm.detail.assignTime || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("驻场操作完成时间：")]),
          _vm._v(_vm._s(_vm.detail.finishTicketTime || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("结单时间：")]),
          _vm._v(_vm._s(_vm.detail.completeTime || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("截止时间：")]),
          _vm._v(_vm._s(_vm.detail.deadline || "--"))
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }